import React from 'react'
import styled from '@emotion/styled'
import { useRouterLoading } from '@common/utils/use-hooks'
import { Global, css } from '@emotion/react'

interface ProgressProps {
  color?: string
  height?: number
  isCustom?: boolean
  className?: string
}

const ProgressWrapper = styled.span``

const returnNProgress: React.FC<React.PropsWithChildren<ProgressProps>> = ({
  color,
  height,
  className,
}) => {
  return (
    <ProgressWrapper className={className}>
      <Global
        styles={css`
          #nprogress {
            pointer-events: none;
          }
          #nprogress .bar {
            background: ${color};
            position: fixed;
            z-index: 1031;
            top: 0;
            left: 0;
            width: 100%;
            height: ${height}px;
          }
          #nprogress .peg {
            display: block;
            position: absolute;
            right: 0px;
            width: 100px;
            height: 100%;
            box-shadow: 0 0 10px ${color}, 0 0 5px ${color};
            opacity: 1;
            -webkit-transform: rotate(3deg) translate(0px, -4px);
            -ms-transform: rotate(3deg) translate(0px, -4px);
            transform: rotate(3deg) translate(0px, -4px);
          }
          #nprogress .spinner {
            display: 'block';
            position: fixed;
            z-index: 1031;
            top: 15px;
            right: 15px;
          }
          #nprogress .spinner-icon {
            width: 18px;
            height: 18px;
            box-sizing: border-box;
            border: solid 2px transparent;
            border-top-color: ${color};
            border-left-color: ${color};
            border-radius: 50%;
            -webkit-animation: nprogresss-spinner 400ms linear infinite;
            animation: nprogress-spinner 400ms linear infinite;
          }
          .nprogress-custom-parent {
            overflow: hidden;
            position: relative;
          }
          .nprogress-custom-parent #nprogress .spinner,
          .nprogress-custom-parent #nprogress .bar {
            position: absolute;
          }
          @-webkit-keyframes nprogress-spinner {
            0% {
              -webkit-transform: rotate(0deg);
            }
            100% {
              -webkit-transform: rotate(360deg);
            }
          }
          @keyframes nprogress-spinner {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      />
    </ProgressWrapper>
  )
}

const Progress: React.FC<React.PropsWithChildren<ProgressProps>> = (props) => {
  const [,] = useRouterLoading({
    showSpinner: false,
  })
  return returnNProgress(props)
}

export default Progress
