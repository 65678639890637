import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

import NProgress from 'nprogress'
import Router from 'next/router'
import { createPopper } from '@popperjs/core'
import debounce from 'lodash/debounce'

export type CallbackRef = (node: HTMLElementOrNull) => any
export type HTMLElementOrNull = HTMLElement | null

export function useIsMounted(): () => boolean {
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  return useCallback(() => isMounted.current, [])
}

/**
 * useOutsideClickRef hook
 *
 * I didn't want to install an additional package. If we do end up
 * using Rooks, let's remove this and use package code
 *
 * https://github.com/imbhargav5/rooks/blob/master/packages/shared/useOutsideClickRef.ts
 *
 * Checks if a click happened outside a Ref. Handy for dropdowns, modals and popups etc.
 * @param handler Callback to fire on outside click
 * @param when A boolean which which activates the hook only when it is true. Useful to conditionally enable the outside click
 * @returns An array with first item being ref
 */
export const useOutsideClickRef = (
  handler: (e: MouseEvent) => any,
  when = true,
): [CallbackRef] => {
  const savedHandler = useRef(handler)
  const [node, setNode] = useState<HTMLElementOrNull>(null)

  const memoizedCallback = useCallback(
    (e: MouseEvent) => {
      if (node && !node.contains(e.target as Element)) {
        savedHandler.current(e)
      }
    },
    [node],
  )

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  const ref = useCallback((nodeRef: HTMLElementOrNull) => {
    setNode(nodeRef)
  }, [])

  useEffect(() => {
    if (when) {
      document.addEventListener('click', memoizedCallback)
      document.addEventListener('ontouchstart', memoizedCallback)
      return () => {
        document.removeEventListener('click', memoizedCallback)
        document.removeEventListener('ontouchstart', memoizedCallback)
      }
    }
  }, [when, memoizedCallback])

  return [ref]
}

export const useOutsideClick = (ref, handler, when = true) => {
  const callbackRef = useRef() // initialize mutable callback ref
  useEffect(() => {
    callbackRef.current = handler
  }, [handler])

  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (Array.isArray(ref.current) && ref.current && ref.current.length > 0) {
        const refResult = ref.current.some((r) => r.contains(event.target))
        if (refResult) {
          return
        }
      } else if (!ref.current || ref?.current?.contains?.(event.target)) {
        return
      }

      if (callbackRef && callbackRef.current) {
        const callback = callbackRef.current as any
        callback(event)
      }
    }

    if (when) {
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    }
  }, [ref, when])
}

/**
 * Detects if there is overflow on the X
 * @returns tuple [isOverflowed: boolean, ref]
 */
export const useOverflowDetect = () => {
  const [isOverflowed, setIsOverflowed] = useState(false)
  const overflowRef = (node) => {
    if (node !== null) {
      const { scrollWidth, offsetWidth } = node
      const overflow = offsetWidth < scrollWidth && offsetWidth !== scrollWidth
      setIsOverflowed(overflow)
    }
  }
  return [isOverflowed, overflowRef]
}

export const useOverflow = (props): any => {
  const { detectX, detectY } = props
  const [isXOverflowed, setIsXOverflowed] = useState(false)
  const [isYOverflowed, setIsYOverflowed] = useState(false)

  const overflowRef = (node) => {
    if (node !== null) {
      const { scrollWidth, clientWidth, clientHeight, scrollHeight } = node

      if (detectX) {
        setIsXOverflowed(scrollWidth > clientWidth)
      }

      if (detectY) {
        setIsYOverflowed(scrollHeight > clientHeight)
      }
    }
  }
  return [{ isXOverflowed, isYOverflowed }, overflowRef]
}

export const usePopper = (popperConfig) => {
  const [toggledState, setToggledState] = useState(false)
  const [popperInstance, setPopperInstance] = useState(null)

  const triggerRef = useRef()
  const popupRef = useRef()

  const showHandler: any = useCallback(() => {
    setToggledState(true)
  }, [])

  const hideHandler: any = useCallback(() => {
    setToggledState(false)
  }, [])

  useEffect(() => {
    const popper = createPopper(
      triggerRef.current,
      popupRef.current,
      popperConfig || {},
    )

    setPopperInstance(popper)

    return () => popper.destroy()
  }, [popperConfig])

  useEffect(() => {
    /* eslint-disable-next-line no-unused-expressions */
    toggledState && popperInstance.forceUpdate()
  }, [toggledState, popperInstance])

  return [triggerRef, popupRef, toggledState, showHandler, hideHandler]
}

/**
 * Combines many refs into one. Useful for combining many ref hooks
 */
export const useCombinedRefs = (...refs) =>
  useCallback(
    (element) =>
      refs.forEach((ref) => {
        if (!ref) {
          return
        }

        // Ref can have two types - a function or an object. We treat each case.
        if (typeof ref === 'function') {
          return ref(element)
        }

        // As per https://github.com/facebook/react/issues/13029
        // it should be fine to set current this way.
        ref.current = element
      }),
    [refs],
  )

// use previous value, useful for evaluation prev props
export function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef()

  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current
}

// https://stackoverflow.com/questions/54666401/how-to-use-throttle-or-debounce-with-react-hook
/**
 * @deprecated Use the npm package use-debounce instead
 * @param cb
 * @param delay
 * @returns
 */
export function useDebounce(cb, delay) {
  const isMounted = useIsMounted() // helper hook, check the code snippet
  const options = {
    trailing: true,
  }
  const inputsRef = useRef(cb) // like `useThrottle`, also remember current delay
  useEffect(() => {
    inputsRef.current = { cb, delay }
  })

  return useCallback(
    debounce(
      (...args: any[]) => {
        // Don't execute callback, if (1) component has been unmounted or
        // (2) delay has changed in the meanwhile
        if (inputsRef.current.delay === delay && isMounted())
          inputsRef.current.cb(...args)
      },
      delay,
      options,
    ),
    [delay, debounce],
  )
}

export const useRouterLoading = ({ isCustom = false, showSpinner }) => {
  // provide ability to use custom loader
  // instead of NProgress
  NProgress.configure({ showSpinner })
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const start = () => (isCustom ? setLoading(true) : NProgress.start())
    const end = () => (isCustom ? setLoading(false) : NProgress.done())
    Router.events.on('routeChangeStart', start)
    Router.events.on('routeChangeComplete', end)
    Router.events.on('routeChangeError', end)
    return () => {
      Router.events.off('routeChangeStart', start)
      Router.events.off('routeChangeComplete', end)
      Router.events.off('routeChangeError', end)
    }
  }, [])
  return [loading]
}

const getIndexById = (arr, matchId) => arr.findIndex(({ id }) => id === matchId)

export const useStep = ({
  initialStep = 0, // accept number or string (if string convert to index)
  autoAdvanceDuration: autoAdvanceDurationProp = 0,
  steps: stepsProp,
}) => {
  const error = (msg) => {
    throw new Error(msg)
  }

  if (process.env.NODE_ENV !== 'production') {
    if (!Array.isArray(stepsProp) && !Number.isInteger(stepsProp)) {
      error(
        'useStep: You must specify either an array or an integer for `steps`',
      )
    }
  }

  // Convert steps to an array if it is a number.
  const steps =
    typeof stepsProp === 'number' ? new Array(stepsProp).fill({}) : stepsProp

  // Compute initialStepIndex in case an id is passed vs an index.
  const initialStepIndex =
    typeof initialStep === 'number'
      ? initialStep
      : getIndexById(steps, initialStep)

  if (process.env.NODE_ENV !== 'production') {
    if (typeof initialStep === 'string' && initialStepIndex === -1) {
      error(
        `useStep: id of "${initialStep}" specified in initialStep not found in steps`,
      )
    }
  }

  // Setup state.
  const [index, setIndex] = useState(initialStepIndex)

  const [isPaused, setPaused] = useState(false)
  const step = steps[index]

  const { autoAdvanceDuration = autoAdvanceDurationProp } = step

  const deltaSetStep = useCallback(
    (delta = 1) => {
      setIndex((initialStepIndex + steps.length + delta) % steps.length)
    },
    [steps, initialStepIndex],
  )

  useEffect(() => {
    setIndex(initialStepIndex)
  }, [initialStepIndex])

  useEffect(() => {
    const timer =
      !isPaused && autoAdvanceDuration
        ? setTimeout(deltaSetStep, autoAdvanceDuration)
        : null
    return () => clearTimeout(timer)
  }, [isPaused, autoAdvanceDuration, deltaSetStep])

  // Build navigation callback functions.
  const navigation = {
    next: () => deltaSetStep(1),
    previous: () => deltaSetStep(-1),
    go: (newStep) => {
      if (typeof newStep === 'number') {
        if (process.env.NODE_ENV !== 'production') {
          if (newStep < 0 || newStep > steps.length) {
            error(`useStep: Index out of range in go(${newStep})`)
          }
        }
        setIndex(newStep)
      } else {
        const newStepId = getIndexById(steps, newStep)
        if (process.env.NODE_ENV !== 'production') {
          if (newStepId === -1) {
            error(`useStep: go("${newStep}") not found in steps`)
          }
        }
        setIndex(newStepId)
      }
    },
    play: () => setPaused(false),
    pause: () => setPaused(true),
  }

  return {
    autoAdvanceDuration,
    isPaused,
    index,
    step,
    navigation,
  }
}

interface UseDynamicSVGImportOptions {
  onCompleted?: (
    name: string,
    SvgIcon:
      | React.FC<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>>
      | undefined,
  ) => void
  onError?: (err: Error) => void
}

export const useDynamicSVGImport = (
  name,
  options: UseDynamicSVGImportOptions,
) => {
  const ImportedIconRef = useRef()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  const { onCompleted, onError } = options
  useEffect(() => {
    setLoading(true)
    const importIcon = async () => {
      try {
        ImportedIconRef.current = (await import(`${name}.svg`)).ReactComponent
        if (onCompleted) {
          onCompleted(name, ImportedIconRef.current)
        }
      } catch (err) {
        if (onError) {
          onError(err)
        }
        setError(err)
      } finally {
        setLoading(false)
      }
    }
    importIcon()
  }, [name, onCompleted, onError])

  return { error, loading, SvgIcon: ImportedIconRef.current }
}

/**
 * Helps tracking the props changes made in a react functional component.
 *
 * Prints the name of the properties/states variables causing a render (or re-render).
 * For debugging purposes only.
 *
 * @usage You can simply track the props of the components like this:
 *  useRenderingTrace('MyComponent', props);
 *
 * @usage You can also track additional state like this:
 *  const [someState] = useState(null);
 *  useRenderingTrace('MyComponent', { ...props, someState });
 *
 * @param componentName Name of the component to display
 * @param propsAndStates
 * @param level
 *
 * @see https://stackoverflow.com/a/51082563/2391795
 */
export const useRenderingTrace = (
  componentName: string,
  propsAndStates: any,
  level: 'debug' | 'info' | 'log' = 'debug',
) => {
  const prev = useRef(propsAndStates)

  useEffect(() => {
    const changedProps: { [key: string]: { old: any; new: any } } =
      Object.entries(propsAndStates).reduce(
        (property: any, [key, value]: [string, any]) => {
          if (prev.current[key] !== value) {
            property[key] = {
              old: prev.current[key],
              new: value,
            }
          }
          return property
        },
        {},
      )

    if (Object.keys(changedProps).length > 0) {
      console[level](`[${componentName}] Changed props:`, changedProps)
    }

    prev.current = propsAndStates
  })
}

export const useDebouncedCallback = () => {
  const { current } = useRef({
    timer: null,
  })
  const [timer, setTimer] = useState(null)

  const debouncedCallback = (
    callback: () => Promise<void> | void,
    timeout: number,
  ) => {
    if (current.timer) clearTimeout(current.timer)

    current.timer = setTimeout(async () => {
      current.timer = null
      setTimer(null)

      await callback()
    }, timeout)

    setTimer(current.timer)
  }

  return {
    debouncedCallback,
    current: {
      timer,
    },
  }
}

export const useLatestRef = <T>(value: T) => {
  const ref = useRef(value)
  useLayoutEffect(() => {
    ref.current = value
  })
  return ref
}
